<template>
  <div class="container">
    <div class="d-flex justify-end mb-4">
      <v-toolbar flat class="mt-n2">
        <v-toolbar-title class="text-body-2 text-md-h5">
          Сообщения
        </v-toolbar-title>
      </v-toolbar>

      <div class="">
        <v-btn color="red" dark class="text-uppercase text-caption text-md-subtitle-1 mb-2">
          Добавить сообщение
        </v-btn>
      </div>
    </div>

    <v-expansion-panels>
      <v-expansion-panel class="mb-4" v-for="i in 3" :key="i">
        <v-expansion-panel-header class="black--text">
          <div class="">
            <v-icon color="red" left>
              mdi-information
            </v-icon>
              Заголовок сообщения, дата
          </div>
          <template v-slot:actions>
            <v-icon color="red">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </div>
          <div class="d-flex mt-2">
            <v-dialog v-model="deleteDialog" persistent max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon color="red" v-bind="attrs" v-on="on">
                  <v-icon size="28">mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline text-body-1 text-md-h5">
                  Вы действительно хотите удалить запись об этом соревновании?
                </v-card-title>
                <v-card-text>Запись будет удалена из базы данных без возможности восстановления.</v-card-text>
                <v-card-actions>
                  <v-btn text @click="deleteDialog = false">Отмена</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn dark color="red" @click="deleteDialog = false">Удалить</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-dialog v-model="editDialog" persistent max-width="400px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text icon color="red">
                  <v-icon size="28">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h6">Карточка соревнований</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" class="mb-n6">
                        <v-text-field
                          label="Название соревнований*"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4" class="mb-n6">
                        <v-text-field
                          label="Адрес и место проведения соревнований*"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" class="mb-n6">
                        <v-dialog class="mb-6" ref="dialog" v-model="dateModal" :return-value.sync="competitionDate" persistent
                          width="350px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field required v-model="competitionDate"
                              label="Укажите дату проведения соревнований"
                              prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                            </v-text-field>
                          </template>
                          <v-date-picker color="red" v-model="competitionDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dateModal = false">
                              Отмена
                            </v-btn>
                            <v-btn color="red" text @click="$refs.dialog.save(competitionDate)">
                              Принять
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-dialog ref="dialog2" v-model="timeModal"
                          :return-value.sync="competitionTime"
                          persistent width="350px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field required v-model="competitionTime"
                              label="Укажите время начала соревнований"
                              prepend-icon="mdi-clock-time-four-outline"
                              v-bind="attrs" v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker v-if="timeModal" v-model="competitionTime"
                            full-width format="24hr" color="red">
                            <v-spacer></v-spacer>
                            <v-btn text @click="timeModal = false">
                              Отмена
                            </v-btn>
                            <v-btn text color="red" @click="$refs.dialog2.save(competitionTime)">
                              Принять
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-textarea required outlined clearable clear-icon="mdi-close-circle"
                          label="Дополнительная информация" v-model="competitionDescription"
                        ></v-textarea>
                      </v-col>

                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="mt-n8">
                  <v-btn text @click="editDialog = false">
                    Отмена
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="editDialog = false">
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

</div>
</template>

<script>
export default {
data: () => ({
  deleteDialog: false,
  editDialog: false,
  dateModal: false,
  competitionDate: null,
  competitionTime: null,
  timeModal: false,
  competitionDescription: "Укажите информацию в этом поле",
})
}
</script>